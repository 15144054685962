.value {
    display: flex;
    flex-direction: row;
}

.value>span:first-child {
    flex: 1;
    font-weight: bold;
}

.value>span:last-child {
    flex: 5;
}