.foot {
    background-color: #009E60;
    color: wheat;
    padding: 15px;

    position:absolute; 
    bottom:0; 
    width:100%;
    font-size: 1vw;
    overflow-x: scroll;
}

.foot h3 {
    font-size: 1.5vw;
}

.copy {
    text-align: center;
    padding-top: 3vh;
}

.qr {
    max-width: calc(min(25vh, 25vw));
    max-height: calc(min(25vh, 25vw));
}

.logo {
    background-color: white;
    max-width: calc(min(25vh, 25vw));
    max-height: calc(min(20vh, 20vw));
}
