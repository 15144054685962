.doubleColumn {
    display: flex;
    flex-direction: row;
}

.doubleColumn>div {
    flex: 1;
}

@media (max-width: 50rem) {
    .doubleColumn {
        flex-direction: column;
    }
    .doubleColumn>div:last-child {
        margin-top: 30px;
    }
}