.App {
  text-align: center;
}

.bg-custom {
  background-color: #e6e3e0;
}

html, body {
  height: 100%;
}

.Site {
  position: relative;
  min-height: 100vh;
}

.main {
  padding-bottom: calc(min(28vh, 28vw));
}