.radio {
    font-size: .8rem;
}

label {
    padding: 0 10px;
}

@media (min-width: 30rem) {
    .radio {
        font-size: calc(.8rem + ((100vw - 30rem) / 70 * .7));
    }
}

@media (min-width: 100rem) {
    .radio {
        font-size: 1.5rem;
    }
}