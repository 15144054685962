.textPage {
    padding: 30px;
}

.textPage h1 {
    font-size: 1rem;
}

.textPage h2 {
    font-size: .9rem;
}

.textPage h3 {
    font-size: .8rem;
}

.textPage h4 {
    font-size: .7rem;
}

.textPage p {
    font-size: .6rem;
}

.textPage ol li:marker {
    font-size: .6rem;
}

.textPage li {
    font-size: .6rem;
}

@media (min-width: 30rem) {
    .textPage h1 {
        font-size: calc(1rem + ((100vw - 30rem) / 70));
    }
    .textPage h2 {
        font-size: calc(.9rem + ((100vw - 30rem) / 70 * .9));
    }
    .textPage h3 {
        font-size: calc(.8rem + ((100vw - 30rem) / 70 * .9));
    }
    .textPage h4 {
        font-size: calc(.7rem + ((100vw - 30rem) / 70 * .8));
    }
    .textPage p {
        font-size: calc(.6rem + ((100vw - 30rem) / 70 * .7));
    }
    .textPage ol li:marker {
        font-size: calc(.6rem + ((100vw - 30rem) / 70 * .7));
    }
    .textPage li {
        font-size: calc(.6rem + ((100vw - 30rem) / 70 * .7));
    }
}

@media (min-width: 100rem) {
    .textPage h1 {
        font-size: 2rem;
    }
    .textPage h2 {
        font-size: 1.8rem;
    }
    .textPage h3 {
        font-size: 1.7rem;
    }
    .textPage h4 {
        font-size: 1.5rem;
    }
    .textPage p {
        font-size: 1.3rem;
    }
    .textPage ol li:marker {
        font-size: 1.3rem;
    }
    .textPage li {
        font-size: 1.3rem;
    }
}